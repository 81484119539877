<template>
    <el-dialog :visible.sync="visible" width="500px" title="" @open="onOpen" @close="onClose" :before-close="beforeClose">
      <div class="wrap">
        <template v-if="type === 1">
          <div>
            <img src="../assets/img/kefu.png" alt="">
          </div>
          <p>微信扫码添加</p>
          <h1>”小慧同学微信“</h1>
        </template>

        <template v-else-if="type === 2">
          <div>
              <img src="../assets/img/weixin.png" alt="">
          </div>
          <p>打开微信"扫一扫"关注公众号</p>
          <h1>”管慧云公众号“</h1>
        </template>
        <template v-else-if="type === 3">
          <div>
            <img src="../assets/img/qq.png" alt="">
          </div>
          <p>打开手机QQ”扫一扫“立马加群</p>
          <h1>”管慧云QQ交流群“</h1>
          <p>群号：464865802</p>
        </template>
        <template v-else-if="type === 4">
          <div>
            <img src="../assets/img/MISCode.jpg" alt="">
          </div>
          <p>打开微信”扫一扫“使用小程序</p>
          <h1>”管慧云小程序“</h1>
        </template>
      </div>


    </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    type: Number
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$emit('update:visible', false);
    },
    beforeClose() {
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap{
  padding-top: 16px;
  width: 230px;
  margin: 0 auto;
  text-align: center;
  img{
    width: 100%;
  }
  p{
    margin-bottom: 18px;
  }
  h1{
    font-size: 18px;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 8px;
  }
}
</style>
