<template>
  <div class="home">

    <div :style="style">
      <page-one @open="handleOpenDialog"/>
    </div>
    <div :style="style">
      <page-two/>
    </div>
    <div :style="style">
      <page-three/>
    </div>
    <div class="banner">
      <img src="@/assets/img/banner-bottom.png" alt="">
      <div class="button" @click="handleToTop">免费试用</div>
    </div>
    <footer>
      <div class="footer-wrap">

        <span>©管慧数据科技有限公司 & 管慧数科院</span>
        <a href="https://beian.miit.gov.cn/">苏ICP备17019654号-5</a>

        <span><img src="@/assets/img/icon1.png" /> 苏公网安备 32059002002313号 </span>
        <span>
              <img src="@/assets/img/icon2.png" />
      </span>
      </div>
    </footer>
    <div class="drawer" @click.stop :class="{active}">
      <div class="drawer-wrap">
        <ul>
          <li @click="handleOpenDialog({type: 1})">
            <el-tooltip content="客服" placement="top">
              <i class="iconfont icon-customer-service"></i>
            </el-tooltip>
          </li>
          <li @click="handleOpenDialog({type: 2})">
            <el-tooltip content="微信" placement="top">
              <i class="iconfont icon-wechat"></i>
            </el-tooltip>
          </li>
          <li @click="handleOpenDialog({type: 3})">
            <el-tooltip content="QQ" placement="top">
              <i class="iconfont icon-qq"></i>
            </el-tooltip>
          </li>
          <li @click="handleOpenDialog({type: 4})">
            <el-tooltip content="支付宝" placement="top">
              <i class="iconfont icon-applet"></i>
            </el-tooltip>
          </li>
        </ul>
      </div>
      <div class="arrow" @click="active = !active">
        <i class="iconfont icon-arrow-d-right"></i>
      </div>
    </div>
    <qrcode-dialog :type="type" :visible.sync="visible" />

  </div>
</template>

<script>
import pageOne from '@/components/pageOne'
import pageTwo from "@/components/pageTwo";
import pageThree from "@/components/pageThree";
import qrcodeDialog from "@/components/qrcodeDialog";

export default {
  name: 'Home',
  data() {
    return {
      height: 1080,
      active: false,
      visible: false,
      type: 1,
    }
  },
  computed: {
    style() {
      return {
        height: `${this.height}px`
      }
    }
  },
  methods: {
    handleToTop() {
      window.scrollTo(0,0)
    },
    handleOpenDialog({type}){
      this.type = type
      this.visible = true
    }
  },
  components: {
    pageOne,
    pageTwo,
    pageThree,
    qrcodeDialog
  },
  created() {
    this.height = document.body.clientHeight
    window.onresize = () => {
      this.height = document.body.clientHeight
    }
    document.addEventListener('click', () => {
      this.active = false
    })
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;

  ::v-deep .wrap {
    height: 100%;
  }
  ::v-deep .contain{
    width: 1500px;
  }

  @media (max-width: 1500px) {
    ::v-deep .contain{
      width: 1366px;
    }
  }
  @media (max-width: 1366px) {
    ::v-deep .contain{
      width: 1200px;
    }
  }
}

.banner {
  position: relative;

  img {
    width: 100%;
  }

  .button {
    width: 272px;
    height: 40px;
    background: hsla(0, 0%, 100%, .2);
    text-align: center;
    line-height: 40px;
    color: #fff;
    position: absolute;
    bottom: 40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 3px;
    cursor: pointer;
  }
}
footer{
  height: 50px;
  background: #f5f5f5;
  line-height: 50px;
  text-align: center;
  color: #666;
  font-size: 12px;
  span {
    &+span{
      margin-left: 64px;
    }
  }
  img{
    vertical-align: middle;
  }
  .footer-wrap{
    width: 1100px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
}
.drawer{
  position: fixed;
  right: 0;
  z-index: 1;
  background: #fff;
  border-radius: 5px 0 0 5px;
  padding-right: 25px;
  bottom: 100px;
  -webkit-box-shadow: 0 4px 8px rgb(0 0 0 / 16%);
  box-shadow: 0 4px 8px rgb(0 0 0 / 16%);
  .drawer-wrap{
    width: 36px;
    overflow: hidden;;
    transition: width .3s cubic-bezier(.43,1.03,.24,1.44);
  }
  &.active{
    .drawer-wrap{
      width: 144px;
    }
    .arrow{
      i{
        display: inline-block;
        transform: rotate(180deg);
      }
    }
  }
  ul{
    width: 144px;
    display: flex;
  }
  li{
    flex: 1;
    text-align: center;
    padding: 5px 0;
  }
  .arrow{
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .iconfont{
    font-size: 20px;
    color: #0097d7;
    cursor: pointer;
  }
}
</style>
