<template>
  <div class="wrap">
    <div class="contain">
      <header>
        <div class="number">01</div>
        <div class="text">
          <h1>爱万联成员展示</h1>
          <div class="line"></div>
          <p class="tips"> Member show </p>
        </div>
      </header>
      <main class="swiper-container before">
        <div class="swiper-wrapper">
          <div class="item swiper-slide" v-for="(item,index) in list" :key="index">
            <ul>
              <li v-for="(it, i) in item" :key="`${index}-${i}`"
                  :style="{backgroundPosition: `-${178*index}px -${114*i}px`}"></li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  data() {
    return {
      list: [
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
        ['', '', '', '', '', ''],
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper('.swiper-container', {
        direction: 'vertical',
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,//循环
        speed: 2500,//滚动速度
        slidesPerView : 6,//slide可见数量
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  .contain {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 auto;
  }

  header {
    height: 140px;
    display: flex;
    margin-bottom: 40px;

    .number {
      height: 100%;
      width: 85px;
      background: #069bda;
      border-radius: 0 0 45px 45px;
      line-height: 160px;
      font-size: 50px;
      text-align: center;
      color: #fff;
      margin-right: 15px;
    }

    .text {
      padding-top: 52px;

      h1 {
        color: #333;
        font-size: 28px;
        line-height: 32px;
      }

      .line {
        height: 4px;
        width: 40px;
        background: #1dbbe7;
        margin: 7px 0 8px;
      }

      .tips {
        font-size: 14px;
        color: #999;
      }
    }
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }

  main {
    flex: 1;
    overflow: hidden;


    ul {
      display: flex;
      justify-content: space-between;
    }
  }

  .item {
    height: 114px;
    margin-bottom: 5px;

    li {
      width: 178px;
      height: 114px;
      background: url(../assets/img/banner-2.png) no-repeat;
    }
  }
}
.before{
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0));
    z-index: 2;
  }
}
</style>
