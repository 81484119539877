<template>
  <div class="wrap">
    <div class="contain">
      <header>
        <div class="logo"></div>
        <div class="right">
          <ul>
            <li>
              <a href="http://bbs.mingbaiyun.com/portal.php?mod=list&catid=1" target="_blank">资讯</a>
            </li>
            <li>
              <a href="javascript:void(0);">帮助</a>
            </li>
            <li>
              <a href="https://www.mingbaiyun.com">管慧云登录</a>
            </li>
            <li>
              <a href="http://bbs.mingbaiyun.com/forum.php" target="_blank">管慧之家</a>
            </li>
          </ul>
        </div>
      </header>
      <div class="main">
        <div class="left"></div>
        <div class="right">
          <div class="work-wrap">
            <ul>
              <li @click="handleChange(1)">
                <img src="../assets/img/item-1.png" alt="">
                <p>设备管理</p>
              </li>
              <li @click="handleChange(2)">
                <img src="../assets/img/item-3.png" alt="">
                <p>广告发布</p>
              </li>
              <li @click="handleChange(3)">
                <img src="../assets/img/item-2.png" alt="">
                <p>车辆管理</p>
              </li>
            </ul>
            <div class="text" v-if="active === 1 || active === 3" :class="{active: active===3}">
              <div v-if="active === 1">
                <p>工业设备通过嵌入智能模组或集成SDK的方式连接到天工万联。适合无自有云平台的设备制造商，或者希望产品上架管慧有品的开发者。</p>
                <div class="button"  @click="handleDirect">
                <span>
                  立即登陆
                  <i class="iconfont icon-arrow-d-right"></i>
                </span>
                </div>
              </div>
              <div v-else-if="active === 3">
                <p>商用车辆、工程机械、农用机械 通过嵌入智能模组或集成SDK的方式连接到天工万联。适合无自有云平台的主机厂商，或者希望产品上架管慧有品的开发者。</p>
                <div class="button" @click="$emit('open', {type: 1})">
                  <span>联系客服</span>
                  <i class="iconfont icon-arrow-d-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message">
      <div class="top">
        <ul><li></li><li></li><li></li></ul>
      </div>
      <div class="middle" >
        <div class="swiper-container">

          <div class="swiper-wrapper">
            <div class="swiper-slide item" v-for="(item,index) in list" :key="index" @click="handleDirect3(item)">
              <p>{{ item.name }}</p>
              <div></div>
              <span>{{item.createdAt}}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="bottom">
        <a href="http://bbs.mingbaiyun.com/portal.php?mod=list&amp;catid=1" target="_blank">more <i class="iconfont icon-arrow-d-right"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  data(){
    return{
      active: 1,
      list:[
        {
          name: '实施ERP对企业财务管理的意义',
          url: 'portal.php?mod=view&aid=24',
          createdAt: '2020-4-2 17:05'
        },
        {
          name: '什么样的CRM系统适合中小微企业',
          url: 'portal.php?mod=view&aid=22',
          createdAt: '2020-4-2 16:58'
        },
        {
          name: 'OA系统如何给企业带来巨大价值',
          url: 'portal.php?mod=view&aid=23',
          createdAt: '2020-4-2 16:58'
        },
        {
          name: 'WMS物流仓储管理系统的优势',
          url: 'portal.php?mod=view&aid=21',
          createdAt: '2020-4-2 14:02'
        },
        {
          name: 'OA系统帮助企业解决办公用品管理难点',
          url: 'portal.php?mod=view&aid=20',
          createdAt: '2020-4-2 13:57'
        },
        {
          name: '企业成功实施ERP系统需注意哪些事项？',
          url: 'portal.php?mod=view&aid=19',
          createdAt: '2020-4-2 13:49'
        },
        {
          name: 'OA系统在企业中占领着重要的位置？',
          url: 'portal.php?mod=view&aid=18',
          createdAt: '2020-4-2 13:46'
        },
        {
          name: '企业如何实施好OA流程管理',
          url: 'portal.php?mod=view&aid=17',
          createdAt: '2020-4-2 13:44'
        },
        {
          name: '管慧有品商城',
          url: 'portal.php?mod=view&aid=16',
          createdAt: '2020-4-2 13:06'
        },
        {
          name: 'IT顾问求职',
          url: 'portal.php?mod=view&aid=15',
          createdAt: '2020-4-2 13:05'
        },
        {
          name: '招聘IT顾问',
          url: 'portal.php?mod=view&aid=14',
          createdAt: '2020-4-2 13:03'
        },
      ],
      swiper: null,
    }
  },
  methods:{
    handleChange(type){
      if(type === 2){
        window.open('http://106.15.72.215/sysmgr/index.html#/')
        return
      }
      this.active =type;
    },
    handleDirect(){
      window.open('https://iot.mingbaiyun.com/stMill/index.html')
    },
    handleDirect2(){
        this.visible = true
    },
    handleDirect3(item){
      window.open('https://bbs.mingbaiyun.com/' + item.url)
    },
  },
  mounted() {
    this.$nextTick(() => {
      const mySwiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,//循环
        speed: 2500,//滚动速度
        slidesPerView : 6,//slide可见数量
        pauseOnMouseEnter: true

      })
    })
  }
}
</script>

<style lang="scss" scoped>
.wrap{
  background: url(../assets/img/bg.jpg) no-repeat;
  background-size: cover;
  position: relative;
}
.contain{
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main{
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  .left{
    width: 800px;
    height: 568px;
    background: url("../assets/img/banner.png") no-repeat;
    background-size: cover;
    transform: scale(.8);
  }
  .right{
    flex: 1;
    overflow: hidden;
    height: 100%;
    position: relative;
    .work-wrap{
      position: absolute;
      width: 80%;
      top: 70%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      ul{
        display: flex;
        justify-content: space-between;
      }
      li{
        width: 216px;
        height: 216px;
        position: relative;
        border-radius: 50%;
        cursor: pointer;
        &.active{
          background: hsla(0,0%,100%,.2);
        }
        img{
          width: 140px;
          display: block;
          margin: 25px auto;
        }
        p{
          font-size: 22px;
          color: #fff;
          text-align: center;
        }
      }
      .text{
        width: 100%;
        padding: 18px 18px 0 18px;
        background: hsla(0,0%,100%,.2);
        line-height: 1.5;
        color: #fff;
        margin-top: 25px;
        position: relative;
        border-radius: 4px;
        &.active{
          &:after{
            left: 463px;
          }
        }
        &:after{
          content: "";
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border: 10px solid hsla(0,0%,100%,.2);
          border-right-color: transparent;
          border-bottom-color: transparent;
          transform: rotate(45deg);
          top: -10px;
          left: 90px;
        }
        p{
          padding-bottom: 18px;
        }
        .button{
          line-height: 1;
          text-align: right;
          padding: 0 0 10px 0;
          span{
            text-shadow: 0 4px 4px rgb(0 0 0 / 15%);
            cursor: pointer;
          }
          i{
            vertical-align: middle;
            display: inline-block;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
header{
  height: 61px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo{
    width: 251px;
    height: 36px;
    background: url('../assets/img/logo.png') no-repeat;
    background-size: contain;
    float: left;
  }

  .right{
    li{
      float: left;
      margin: 0 16px;
      line-height: 36px;
      position: relative;
      a{
        color: #fff;
      }
    }
  }
}
 .swiper-container{
  width: 100%;
   height: 500px;
}
.message{
  width: 800px;
  height: 265px;
  background: hsla(0,0%,100%,.2);
  position: absolute;
  top: 120px;
  right: 120px;
  z-index: 1;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  .middle{
    flex: 1;
    overflow: hidden;
    .item{
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 0 16px;
      p{
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      div{
        flex: 1;
        border-top: 1px dashed hsla(0,0%,100%,.2);
        margin: 0 10px;
      }
    }
  }
  .top{
    height: 15px;
    background: hsla(0,0%,100%,.2);
    ul{
      padding: 3px 15px;
    }
    li{
      float: left;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #ec6969;
      &+li{
        margin-left: 10px;
      }
      &:nth-child(2){
        background: #f59c61;
      }
      &:nth-child(3){
        background-color: #4cd5ab;
      }
    }
  }
  .bottom{
    height: 25px;
    padding: 0 16px;
    text-align: right;
    a{
      color: #fff;
      cursor: pointer;
    }
    i{
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
    }
  }
}
.swiper-container{
  height: 230px;
}
.swiper-wrapper{
  transition-timing-function: linear;
}
</style>
