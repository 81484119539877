<template>
  <div class="wrap">
    <div class="contain">
      <header>
        <div class="number">02</div>
        <div class="text">
          <h1>合作伙伴</h1>
          <div class="line"></div>
          <p class="tips">Partnership</p>
        </div>
      </header>
      <main>
        <div class="container">
          <div class="wrap">
            <ul>
              <li>
                <div><img src="@/assets/img/partner1.png" alt=""></div>
              </li>
              <li>
                <div><img src="@/assets/img/partner2.png" alt=""></div>
              </li>
              <li>
                <div><img src="@/assets/img/partner3.png" alt=""></div>
              </li>
              <li>
                <div><img src="@/assets/img/partner4.png" alt=""></div>
              </li>
              <li>
                <div><img src="@/assets/img/partner5.png" alt=""></div>
              </li>
              <li>
                <div><img src="@/assets/img/partner6.png" alt=""></div>
              </li>
              <li>
                <div><img src="@/assets/img/partner7.png" alt=""></div>
              </li>
              <li>
                <div><img src="@/assets/img/partner8.png" alt=""></div>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrap {
  .contain {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 auto;
  }

  header {
    height: 140px;
    display: flex;
    flex-direction: row-reverse;

    .number {
      height: 100%;
      width: 85px;
      background: #069bda;
      border-radius: 0 0 45px 45px;
      line-height: 160px;
      font-size: 50px;
      text-align: center;
      color: #fff;
      margin-left: 15px;
    }

    .text {
      padding-top: 52px;

      h1 {
        color: #333;
        font-size: 28px;
        line-height: 32px;
      }

      .line {
        height: 4px;
        width: 40px;
        background: #1dbbe7;
        margin: 7px 0 8px;
      }

      .tips {
        font-size: 14px;
        color: #999;
      }
    }
  }

  main{
    ul{
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
    }
    li{
      width: 25%;
      text-align: center;
      margin-top: 30px;
      img {
        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        }
      }
    }
  }
}
</style>
